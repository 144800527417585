import { IS_DEV, TEMPLATE_PATH } from "@/constants/AppConstants";
import { HttpClient } from "@/helpers/HttpClient";
import router from '../../router/index'

export const ChessModule = {
    namespaced: true,
    state: {
        data: null,
        loading: true,
        popup: null,
        apartmentNames: null
    },
    actions: {
        async fetchData(store, date) {
            store.commit("Chess/LOADING", true, { root: true });
            const httpClient = new HttpClient({});
            const URL = TEMPLATE_PATH + '/api/getChessboard';

            const data_start_m = String(new Date(date.start).getMonth() + 1).length > 1 ?
                String(new Date(date.start).getMonth() + 1) :
                ("0" + (new Date(date.start).getMonth() + 1));
            const data_start_d = String(new Date(date.start).getDate()).length > 1 ?
                (new Date(date.start).getDate()) :
                ("0" + (new Date(date.start).getDate()));
            const data_start = String(new Date(date.start).getFullYear()) + '-' +
                data_start_m + '-' +
                data_start_d + " 00:00";

            const date_end = date.start + (Math.round(date.count) * 86400000);

            const data_end_m = String(new Date(date_end).getMonth() + 1).length > 1 ?
                String(new Date(date_end).getMonth() + 1) :
                ("0" + (new Date(date_end).getMonth() + 1));
            const data_end_d = String(new Date(date_end).getDate()).length > 1 ?
                new Date(date_end).getDate() :
                "0" + (new Date(date_end).getDate());
            const data_end = new Date(date_end).getFullYear() + '-' +
                data_end_m + '-' +
                data_end_d + " 23:59";

            const formData = new FormData();

            formData.append( "residence_from", data_start);
            formData.append( "residence_to", data_end);
            if(IS_DEV) {
                formData.append("IS_DEV", "true");
            }

            const response = httpClient.post(
                URL,{body: formData,}
            );

            response.then((data) => {
                if(!data.errorMessage) {
                    Object.keys(data['chessboard']).map(i => data['chessboard'][i].OPEN = true)

                    store.commit("Chess/DATA", data['chessboard'], { root: true });
                    store.commit("Chess/LINE_NAMES", data['apartmentNames'], { root: true });

                    store.commit("Chess/LOADING", false, { root: true });
                }
            })
        },
        togglePopup(store, obj) {
            if(obj && store.getters.DATA) {
                const el = store.getters.DATA[obj.parent_id]['ITEMS'][obj.child_id][obj.el_id];
                if(el) {
                    store.commit("Chess/POPUP", el, { root: true });
                }
            } else {
                store.commit("Chess/POPUP", null, { root: true });
            }
        }
    },
    getters: {
        DATA(state) {
            return state.data
        },
        LOADING(state) {
            return state.loading
        },
        POPUP(state) {
            return state.popup
        },
        LINE_NAMES(state) {
            return state.apartmentNames
        },
    },
    mutations: {
        DATA(state, payload) {
            state.data = payload
        },
        LOADING(state, payload) {
            state.loading = payload
        },
        POPUP(state, payload) {
            state.popup = payload
        },
        LINE_NAMES(state, payload) {
            state.apartmentNames = payload
        },
    },
};
