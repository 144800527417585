<template>
  <div class="web-container web-container_blue">
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      <div class="logo-abs" style="margin-top: -50px">
        <LogoSvg />
      </div>
      <div class="auth-cover">
        <div class="auth-cover__content auth-cover__content_big">
          <div class="auth-cover__title">
            Возникли проблемы при входе в личный кабинет?
          </div>
          <div class="auth-cover__form">
            <p><b>На вашу почту отправлено подтверждение</b></p>
            <p>На ваш почтовый ящик отправлено сообщение, содержащее ссылку для подтверждения правильности e-mail адпреса. Пожалуйста, перейдите по ссылке для завершения регистрации.</p>
            <p>Если письмо не пришло в течение 15 минут, проверьте папку «Спам». Если письмо попало в эту папку, откройте письмо, нажмите кнопку «Не спам» и перейдите по ссылке подтверждения. Если же письма нет и в папке «Спам», попробуйте отправить письмо повторно.</p>
            <p>Если письмо так и не пришло, проверьте введенные данные. Возможно, вы ошиблись при вводе адреса.</p>
          </div>
          <div class="auth-cover__footer">
            <div class="btn btn_line-text" @click="loginLink()">Войти</div>
            <div class="btn btn_primary" @click="onSubmit()">Отправить повторно?</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Loader from '@/components/LoaderComponent';
import LogoSvg from '@/components/LogoSvg';

export default {
  name: "RecoverSuccessPage",
  data() {
    return {
      loading: true,
      login: ""
    }
  },
  components: {
    Loader,
    LogoSvg
  },
  computed: {
    ...mapGetters("User", ["USER"]),
    ...mapGetters("User", ["RESET_LOGIN"]),
  },
  methods: {
    ...mapActions("User", ["resetPassword"]),
    loginLink() {
      this.$router.push('/login')
    },
    onSubmit() {
      this.resetPassword(this.RESET_LOGIN);
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>
