class BaseHttpClient {
  constructor(options) {
    this.baseOptions = options;
  }

  jsonRequest(url, options) {
    return fetch(url, {
      ...this.baseOptions,
      ...options,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }

      return response.json().then((data) => {
        throw {
          ...data,
          status: response.status,
          statusText: response.statusText,
        };
      });
    });
  }
}

export class HttpClient extends BaseHttpClient {
  get(url, options) {
    return this.jsonRequest(url, { ...options, method: "GET" });
  }

  post(url, options) {
    return this.jsonRequest(url, { ...options, method: "POST" });
  }

  put(url, options) {
    return this.jsonRequest(url, { ...options, method: "PUT" });
  }

  patch(url, options) {
    return this.jsonRequest(url, { ...options, method: "PATCH" });
  }

  delete(url, options) {
    return this.jsonRequest(url, { ...options, method: "DELETE" });
  }
}
