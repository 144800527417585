import AuthorizationPage from '../page/AuthorizationPage.vue'
import HomePage from '../page/HomePage.vue'
import ChessPage from '../page/ChessPage.vue'
import RecoverPage from '../page/RecoverPage.vue'
import NewPassPage from '../page/NewPassPage.vue'
import RecoverSuccessPage from '../page/RecoverSuccessPage.vue'
import ProfilePage from '../page/ProfilePage.vue'
import { createRouter, createWebHashHistory } from "vue-router"

const routeInfos = [
    {
        path : "/login",
        component : AuthorizationPage
    },
    {
        path : "/recover",
        component : RecoverPage
    },
    {
        path : "/",
        component : HomePage
    },
    {
        path : "/profile",
        component : ProfilePage
    },
    {
        path : "/chess",
        component : ChessPage
    },
    {
        path : "/new_pass",
        component : NewPassPage
    },
    {
        path : "/recover_success",
        component : RecoverSuccessPage
    },
]
const router = createRouter({
    history : createWebHashHistory(),
    routes : routeInfos
})
export default router;
