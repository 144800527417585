<template>
  <div class="web-container web-container_blue">
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      <div class="logo-abs">
        <LogoSvg />
      </div>
      <div class="auth-cover">
        <div class="auth-cover__content auth-cover__content_big">
          <div class="auth-cover__title">
            Возникли проблемы при входе в личный кабинет?
          </div>
          <div class="auth-cover__form">
            <div class="auth-cover__footer-error" v-if="RESET">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g transform="translate(-362 -206)">
                  <g transform="translate(362 206)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M8,16A8,8,0,1,0,0,8,8.024,8.024,0,0,0,8,16Z" stroke="none"/>
                    <path d="M 8 14.5 C 9.727370262145996 14.5 11.3572301864624 13.82145023345947 12.58934020996094 12.58934020996094 C 13.82145023345947 11.3572301864624 14.5 9.727370262145996 14.5 8 C 14.5 6.272630214691162 13.82145023345947 4.642769813537598 12.58934020996094 3.410660028457642 C 11.3572301864624 2.178550004959106 9.727370262145996 1.5 8 1.5 C 6.272630214691162 1.5 4.642769813537598 2.178550004959106 3.410660028457642 3.410660028457642 C 2.178550004959106 4.642769813537598 1.5 6.272630214691162 1.5 8 C 1.5 9.727370262145996 2.178550004959106 11.3572301864624 3.410660028457642 12.58934020996094 C 4.642769813537598 13.82145023345947 6.272630214691162 14.5 8 14.5 M 8 16 C 3.599999904632568 16 0 12.39999961853027 0 8 C 0 3.599999904632568 3.599999904632568 0 8 0 C 12.39999961853027 0 16 3.599999904632568 16 8 C 16 12.39999961853027 12.39999961853027 16 8 16 Z" stroke="none" fill="currentColor"/>
                  </g>
                  <g transform="translate(596.25 435.729) rotate(180)">
                    <path d="M12,7.92v3.7" transform="translate(214.25 210.207)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <path d="M12,16.08a.75.75,0,1,0-.75-.75A.75.75,0,0,0,12,16.08Z" transform="translate(214.25 209.25)" fill="currentColor"/>
                  </g>
                </g>
              </svg>

              {{RESET}}
            </div>
            <div class="flex">
              <div class="flex-col">
                <div class="auth-cover__label">E-mail</div>
              </div>
              <div class="flex-col">
                <input v-model="login" type="text" class="auth-cover__input">
              </div>
            </div>
          </div>
          <div class="auth-cover__footer">
            <div class="btn btn_line-text" @click="loginLink()">Войти</div>
            <div class="btn btn_primary" @click="onSubmit()">Восстановить пароль</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Loader from '@/components/LoaderComponent';
import LogoSvg from '@/components/LogoSvg';

export default {
  name: "AuthorizationPage",
  data() {
    return {
      loading: true,
      login: "",
    }
  },
  components: {
    Loader,
    LogoSvg
  },
  computed: {
    ...mapGetters("User", ["USER"]),
    ...mapGetters("User", ["RESET"]),
  },
  methods: {
    ...mapActions("User", ["resetPassword"]),
    loginLink() {
      this.$router.push('/login')
    },
    onSubmit() {
      this.resetPassword(this.login);
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>
