<template>
  <div class="popup-cover" v-if="POPUP">
    <div class="popup-header">
      {{POPUP['NAME']}} | {{POPUP['CUSTOMER']['LAST_NAME']}} {{POPUP['CUSTOMER']['FIRST_NAME'][0]}}. {{POPUP['CUSTOMER']['MIDDLE_NAME'][0]}}. | № {{POPUP['NAME']}}

      <div class="close" @click="closePopup()">
        <svg width="16" height="16" viewBox="0 0 16 16">
          <g>
            <g transform="translate(8 -5.435) rotate(45)">
              <path d="M0,0H19" transform="translate(0 9.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
              <path d="M0,0V19" transform="translate(9.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <div class="popup-content">
      <template v-if="loading_popup">
        <Loader />
      </template>
      <template v-else>
        <div class="detail">
          <div class="detail-row" v-if="POPUP['STATUS']">
            <div class="detail-label">Статус</div>
            <div class="detail-value" v-html="statusFormat(POPUP['STATUS'])"></div>
          </div>
          <div class="detail-row" v-if="POPUP['NUMBER']">
            <div class="detail-label">№ бронирования</div>
            <div class="detail-value">{{POPUP['NUMBER']}}</div>
          </div>
          <div class="detail-row" v-if="POPUP['CUSTOMER']['FULL_NAME']">
            <div class="detail-label">Заказчик</div>
            <div class="detail-value">{{POPUP['CUSTOMER']['FULL_NAME']}}</div>
          </div>
          <div class="detail-row" v-if="POPUP['CUSTOMER']['PHONES']">
            <div class="detail-label">Телефон</div>
            <div class="detail-value"><a v-for="(phone, phone_i) in POPUP['CUSTOMER']['PHONES']" :href="'tel:' + phone" :key="phone_i">{{phone}}</a></div>
          </div>
          <div class="detail-row" v-if="POPUP['CUSTOMER']['EMAILS']">
            <div class="detail-label">Почта</div>
            <div class="detail-value"><a v-for="(mail, mail_i) in POPUP['CUSTOMER']['EMAILS']" :href="'mailto:' + mail" :key="mail_i">{{mail}}</a></div>
          </div>

          <hr>

          <div class="detail-row" style="flex-direction: column" v-if="POPUP['CUSTOMER_COMMENT']">
            <div class="detail-label">Комментарий гостя</div>
            <div class="detail-value" style="width: 100%;">{{POPUP['CUSTOMER_COMMENT']}}</div>
          </div>

          <hr>

          <div class="detail-row" v-if="POPUP['CHECK_IN_DATE_TIME']">
            <div class="detail-label">Заезд</div>
            <div class="detail-value">{{dateFormat(POPUP['CHECK_IN_DATE_TIME'])}}</div>
          </div>
          <div class="detail-row" v-if="POPUP['CHECK_OUT_DATE_TIME']">
            <div class="detail-label">Выезд</div>
            <div class="detail-value">{{dateFormat(POPUP['CHECK_OUT_DATE_TIME'])}}</div>
          </div>
          <div class="detail-row" v-if="POPUP['COUNT_NIGHTS']">
            <div class="detail-label">Ночей</div>
            <div class="detail-value">{{POPUP['COUNT_NIGHTS']}}</div>
          </div>

          <hr v-if="POPUP['GUEST_COUNT_INFO']">

          <div class="detail-row" v-if="POPUP['GUEST_COUNT_INFO']">
            <div class="detail-label">Взрослых</div>
            <div class="detail-value">{{POPUP['GUEST_COUNT_INFO']['ADULTS']}}</div>
          </div>

          <div class="detail-row" v-if="POPUP['GUEST_COUNT_INFO']">
            <div class="detail-label">Детей</div>
            <div class="detail-value">{{POPUP['GUEST_COUNT_INFO']['CHILDREN']}}</div>
          </div>

          <hr>

          <div class="detail-row" v-if="POPUP['SOURCE']">
            <div class="detail-label">Источник</div>
            <div class="detail-value">{{POPUP['SOURCE']}}</div>
          </div>

          <hr v-if="POPUP['SOURCE']">

          <div class="detail-row">
            <div class="detail-label">Сумма за проживание</div>
            <div class="detail-value">{{priceFormatting(POPUP['AMOUNT'])}} {{POPUP['CURRENCY_ID']}}</div>
          </div>
<!--          <div class="detail-row">
            <div class="detail-label">Сумма за услуги</div>
            <div class="detail-value">1 000 {{POPUP['CURRENCY_ID']}}</div>
          </div>-->
<!--          <div class="detail-row">
            <div class="detail-label">Итого</div>
            <div class="detail-value">6 500 {{POPUP['CURRENCY_ID']}}</div>
          </div>-->
          <div class="detail-row">
            <div class="detail-label">Оплачено</div>
            <div class="detail-value">{{priceFormatting(POPUP['AMOUNT'] - POPUP['TO_PAY_AMOUNT'])}} {{POPUP['CURRENCY_ID']}}</div>
          </div>
          <div class="detail-row">
            <div class="detail-label">К оплате за номер (баланс)</div>
            <div class="detail-value">{{priceFormatting(POPUP['TO_PAY_AMOUNT'])}} {{POPUP['CURRENCY_ID']}}</div>
          </div>

          <hr>

          <div class="detail-row">
            <div class="mail-status mail-status_gray" v-if="POPUP['MAIL_STATUS'] === 'N'">
              <svg width="16" height="16" viewBox="0 0 16 16"><g  transform="translate(-539 -430)"><g transform="translate(25.838 208.8)"><path d="M16.03,8.36V2.75H4.81V8.36" transform="translate(510.797 219.25)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.381,9.28a1.755,1.755,0,0,1,.8,1.475v6.109a1.758,1.758,0,0,1-1.756,1.756H4.506A1.758,1.758,0,0,1,2.75,16.864V10.755a1.755,1.755,0,0,1,.8-1.475" transform="translate(511.25 217.815)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.605,9.59l-5.36,4.689a1.6,1.6,0,0,1-2.114,0L3.77,9.59" transform="translate(511.026 217.747)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M14.13,15.01l4.752,4.065" transform="translate(508.749 216.556)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M3.78,19.09l4.806-4.01" transform="translate(511.024 216.54)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,5.83h.8" transform="translate(510.12 218.573)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,8.92H14.3" transform="translate(510.12 217.894)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></g></svg>
              Не отправлено
              <span>{{POPUP['MAIL_DATE_EXEC']}}</span>
            </div>
            <div class="mail-status mail-status_green" v-if="POPUP['MAIL_STATUS'] === 'Y'">
              <svg width="16" height="16" viewBox="0 0 16 16"><g  transform="translate(-539 -430)"><g transform="translate(25.838 208.8)"><path d="M16.03,8.36V2.75H4.81V8.36" transform="translate(510.797 219.25)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.381,9.28a1.755,1.755,0,0,1,.8,1.475v6.109a1.758,1.758,0,0,1-1.756,1.756H4.506A1.758,1.758,0,0,1,2.75,16.864V10.755a1.755,1.755,0,0,1,.8-1.475" transform="translate(511.25 217.815)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.605,9.59l-5.36,4.689a1.6,1.6,0,0,1-2.114,0L3.77,9.59" transform="translate(511.026 217.747)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M14.13,15.01l4.752,4.065" transform="translate(508.749 216.556)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M3.78,19.09l4.806-4.01" transform="translate(511.024 216.54)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,5.83h.8" transform="translate(510.12 218.573)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,8.92H14.3" transform="translate(510.12 217.894)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></g></svg>
              Успешно отправлено
              <span>{{POPUP['MAIL_DATE_EXEC']}}</span>
            </div>
            <div class="mail-status mail-status_red" v-if="POPUP['MAIL_STATUS'] === 'F'">
              <svg width="16" height="16" viewBox="0 0 16 16"><g  transform="translate(-539 -430)"><g transform="translate(25.838 208.8)"><path d="M16.03,8.36V2.75H4.81V8.36" transform="translate(510.797 219.25)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.381,9.28a1.755,1.755,0,0,1,.8,1.475v6.109a1.758,1.758,0,0,1-1.756,1.756H4.506A1.758,1.758,0,0,1,2.75,16.864V10.755a1.755,1.755,0,0,1,.8-1.475" transform="translate(511.25 217.815)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.605,9.59l-5.36,4.689a1.6,1.6,0,0,1-2.114,0L3.77,9.59" transform="translate(511.026 217.747)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M14.13,15.01l4.752,4.065" transform="translate(508.749 216.556)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M3.78,19.09l4.806-4.01" transform="translate(511.024 216.54)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,5.83h.8" transform="translate(510.12 218.573)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,8.92H14.3" transform="translate(510.12 217.894)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></g></svg>
              Ошибка отправки
              <span>{{POPUP['MAIL_DATE_EXEC']}}</span>
            </div>
            <div class="mail-status mail-status_orange" v-if="POPUP['MAIL_STATUS'] === 'P'">
              <svg width="16" height="16" viewBox="0 0 16 16"><g  transform="translate(-539 -430)"><g transform="translate(25.838 208.8)"><path d="M16.03,8.36V2.75H4.81V8.36" transform="translate(510.797 219.25)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.381,9.28a1.755,1.755,0,0,1,.8,1.475v6.109a1.758,1.758,0,0,1-1.756,1.756H4.506A1.758,1.758,0,0,1,2.75,16.864V10.755a1.755,1.755,0,0,1,.8-1.475" transform="translate(511.25 217.815)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M16.605,9.59l-5.36,4.689a1.6,1.6,0,0,1-2.114,0L3.77,9.59" transform="translate(511.026 217.747)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M14.13,15.01l4.752,4.065" transform="translate(508.749 216.556)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M3.78,19.09l4.806-4.01" transform="translate(511.024 216.54)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,5.83h.8" transform="translate(510.12 218.573)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M7.89,8.92H14.3" transform="translate(510.12 217.894)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></g></svg>
              Частично отправлено
              <span>{{POPUP['MAIL_DATE_EXEC']}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import Loader from '@/components/LoaderComponent';

export default {
  name: "WorkDetailPopup",
  data() {
    return {
      loading_popup: true,
      weeks: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
      month: ["Января","Февряля","Марта","Апреля","Мая","Июня","Июля","Августа","Сентября","Октября","Ноября","Декабря"],
    }
  },
  props: {
    OBJ: Object
  },
  components: {
    Loader,
  },
  computed: {
    ...mapGetters("Chess", ["POPUP"]),
  },
  methods: {
    ...mapActions("Chess", ["togglePopup"]),
    closePopup() {
      this.togglePopup(null)
    },
    statusFormat(status) {
      let html = "";
      if(String(status).toLocaleLowerCase() === 'new') {
        html = `<span class="status status_green">Новая бронь</span>`
      }
      if(String(status).toLocaleLowerCase() === 'checkedout') {
        html = `<span class="status status_yellow">Выезд</span>`
      }
      return html
    },
    dateFormat(date) {
      if(date) {
        const _this = this;
        const dateFormattingIn_h = String(new Date(date).getHours()).length > 1 ? new Date(date).getHours() : ('0' + (new Date(date).getHours()));
        const dateFormattingIn_m = String(new Date(date).getMinutes()).length > 1 ? new Date(date).getMinutes() : ('0' + (new Date(date).getMinutes()));
        const dateFormattingIn = new Date(date).getDate() + ' ' +
            _this.month[new Date(date).getMonth()] + ' ' +  new Date(date).getFullYear() + ' (' +  _this.weeks[new Date(date).getDay()] + ') -' +
            dateFormattingIn_h + ':' + dateFormattingIn_m;

        return dateFormattingIn
      }
    },
    priceFormatting(price) {
      const resString = String(price).split(' ').join('');
      return resString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  },
  mounted() {
    this.loading_popup = false;
  }
}
</script>
