import { IS_DEV, TEMPLATE_PATH } from "@/constants/AppConstants";
import { HttpClient } from "@/helpers/HttpClient";
import router from '../../router/index'

export const UserModule = {
    namespaced: true,
    state: {
        auth_user: null,
        user: null,
        reset_text: false,
        new_pass: false,
        error: null,
        profile_error: null,
        success: false,
        reset_login: null
    },
    actions: {
        async fetchUser(store, auth) {
            const httpClient = new HttpClient({});

            if(IS_DEV) {
                const httpClient = new HttpClient({});

                const URL = IS_DEV ? 'json/user.json' : '';

                const data = await httpClient.get(
                    URL
                );

                if (data) {
                    store.commit("User/USER", data, { root: true });
                    if(auth) {
                        router.push('/chess')
                    }
                } else {
                    if(auth && !auth.auth) {
                        router.push('/login')
                    }
                }
            } else {
                const URL = TEMPLATE_PATH + '/api/profile';
                const response = httpClient.post(
                    URL,
                );
                response.then((data) => {
                    console.log('user', data);
                    if(!data.errorMessage) {
                        store.commit("User/USER", data, { root: true });
                        if(auth) {
                            router.push('/chess')
                        }
                    } else {
                        if(auth && !auth.auth) {
                            router.push('/login')
                        }
                    }
                })
            }
        },
        logOut(store) {
            const httpClient = new HttpClient({});

            const URL = TEMPLATE_PATH + '/api/logout';
            const response = httpClient.post(
                URL,
            );
            response.then((data) => {
                if(!data.errorMessage) {
                    store.commit("User/USER", null, { root: true });
                    router.push('/login')
                }
            })
        },
        authorization(store, obj) {
            store.dispatch("errorText", false);
            const httpClient = new HttpClient({});

            const URL = TEMPLATE_PATH + '/api/signIn';

            const formData = new FormData();
            formData.append( "password", obj.password);
            formData.append( "login", obj.login);
            formData.append( "remember", obj.remember);

            const response = httpClient.post(
                URL, {body: formData,}
            );

            response.then((data) => {
                console.log('login',data);
                if(!data.errorMessage) {
                    store.commit("User/AUTH", true, { root: true });
                    store.dispatch("fetchUser", {auth: true});
                } else {
                    console.log(data);
                    store.dispatch("errorText", data.errorMessage);
                }
            })
        },
        resetPassword(store, email) {
            store.commit("User/RESET", false, { root: true });
            const httpClient = new HttpClient({});

            const URL = TEMPLATE_PATH + '/api/generateCode';

            const formData = new FormData();
            formData.append( "login", email);

            const response = httpClient.post(
                URL, {body: formData,}
            );

            response.then((data) => {
                if(data.errorMessage) {
                    store.commit("User/RESET", data.errorMessage, { root: true });
                } else {
                    store.commit("User/RESET_LOGIN", email, { root: true });
                    router.push('/recover_success')
                }
            })

        },
        newPassword(store, {password, replay_password}) {
            store.commit("User/NEW_PASS", false, { root: true });
            const httpClient = new HttpClient({});

            const URL = TEMPLATE_PATH + '/api/changePassword';

            const formData = new FormData();

            if(window.location.hash) {
                const hash = window.location.hash.split('?');
                let login = '';
                let checkWord = '';
                hash[1].split('&').forEach(el => {
                    if(el.includes('login')) {
                        login = el.split('=')[1]
                    }
                    if(el.includes('checkWord')) {
                        checkWord = el.split('=')[1]
                    }
                })

                formData.append( "checkWord", checkWord);
                formData.append( "login", login);
            }

            formData.append( "password", password);
            formData.append( "confirmPassword", replay_password);

            /*console.log('new', Object.fromEntries(formData))*/

            const response = httpClient.post(
                URL, {body: formData,}
            );

            response.then((data) => {
                if(data.errorMessage) {
                    store.commit("User/NEW_PASS", data.errorMessage, { root: true });
                } else {
                    router.push('/chess')
                }
            })
        },
        newPasswordProfile(store, password) {
            store.commit("User/PROFILE_ERROR", false, { root: true });
            store.commit("User/SUCCESS", false, { root: true });
            const httpClient = new HttpClient({});

            const URL = TEMPLATE_PATH + '/api/changePasswordProfile';

            const formData = new FormData();
            formData.append( "password", password);

            const response = httpClient.post(
                URL, {body: formData,}
            );

            response.then((data) => {
                if(data.errorMessage) {
                    console.log(data);
                    store.commit("User/PROFILE_ERROR", data.errorMessage, { root: true });
                } else {
                    store.commit("User/SUCCESS", data.successMessage, { root: true });
                }
            })
        },
        errorText(store, string) {
            store.commit("User/ERROR", string, { root: true });
        },
        errorTextProfile(store, string) {
            store.commit("User/PROFILE_ERROR", string, { root: true });
        },
        successTextProfile(store, string) {
            store.commit("User/SUCCESS", string, { root: true });
        }
    },
    getters: {
        USER(state) {
            return state.user
        },
        AUTH(state) {
            return state.auth_user
        },
        RESET(state) {
            return state.reset_text
        },
        NEW_PASS(state) {
            return state.new_pass
        },
        ERROR(state) {
            return state.error
        },
        SUCCESS(state) {
            return state.success
        },
        PROFILE_ERROR(state) {
            return state.profile_error
        },
        RESET_LOGIN(state) {
            return state.reset_login
        },
    },
    mutations: {
        USER(state, payload) {
            state.user = payload
        },
        AUTH(state, payload) {
            state.auth_user = payload
        },
        RESET(state, payload) {
            state.reset_text = payload
        },
        NEW_PASS(state, payload) {
            state.new_pass = payload
        },
        ERROR(state, payload) {
            state.error = payload
        },
        PROFILE_ERROR(state, payload) {
            state.profile_error = payload
        },
        SUCCESS(state, payload) {
            state.success = payload
        },
        RESET_LOGIN(state, payload) {
            state.reset_login = payload
        },
    },
};
