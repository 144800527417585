import {createStore} from 'vuex'

import { UserModule } from "@/store/modules/User";
import { ChessModule } from "@/store/modules/Chess";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        User: UserModule,
        Chess: ChessModule,
    }
})
