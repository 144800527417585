<template>
  <div class="web-container web-container_blue">
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      <div class="logo-abs">
        <LogoSvg />
      </div>
      <div class="auth-cover">
        <div class="auth-cover__content">
          <div class="auth-cover__title">Личный кабинет партнера</div>
          <div class="auth-cover__form">
            <div class="auth-cover__footer-error" v-if="ERROR">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g transform="translate(-362 -206)">
                  <g transform="translate(362 206)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M8,16A8,8,0,1,0,0,8,8.024,8.024,0,0,0,8,16Z" stroke="none"/>
                    <path d="M 8 14.5 C 9.727370262145996 14.5 11.3572301864624 13.82145023345947 12.58934020996094 12.58934020996094 C 13.82145023345947 11.3572301864624 14.5 9.727370262145996 14.5 8 C 14.5 6.272630214691162 13.82145023345947 4.642769813537598 12.58934020996094 3.410660028457642 C 11.3572301864624 2.178550004959106 9.727370262145996 1.5 8 1.5 C 6.272630214691162 1.5 4.642769813537598 2.178550004959106 3.410660028457642 3.410660028457642 C 2.178550004959106 4.642769813537598 1.5 6.272630214691162 1.5 8 C 1.5 9.727370262145996 2.178550004959106 11.3572301864624 3.410660028457642 12.58934020996094 C 4.642769813537598 13.82145023345947 6.272630214691162 14.5 8 14.5 M 8 16 C 3.599999904632568 16 0 12.39999961853027 0 8 C 0 3.599999904632568 3.599999904632568 0 8 0 C 12.39999961853027 0 16 3.599999904632568 16 8 C 16 12.39999961853027 12.39999961853027 16 8 16 Z" stroke="none" fill="currentColor"/>
                  </g>
                  <g transform="translate(596.25 435.729) rotate(180)">
                    <path d="M12,7.92v3.7" transform="translate(214.25 210.207)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <path d="M12,16.08a.75.75,0,1,0-.75-.75A.75.75,0,0,0,12,16.08Z" transform="translate(214.25 209.25)" fill="currentColor"/>
                  </g>
                </g>
              </svg>

              {{ERROR}}
            </div>
            <div class="flex">
              <div class="flex-col">
                <div class="auth-cover__label">Логин</div>
              </div>
              <div class="flex-col">
                <input v-model="login" type="text" :class="['auth-cover__input', error ? 'error' : '']">
              </div>
            </div>
            <div class="flex">
              <div class="flex-col">
                <div class="auth-cover__label">Пароль</div>
              </div>
              <div class="flex-col">
                <input v-model="password" :type="type_pass" :class="['auth-cover__input', error ? 'error' : '']">
                <span class="pass-btn" @click="type_pass = type_pass === 'text' ? 'password' : 'text'">
                  <svg width="16" height="16" viewBox="0 0 16 16" v-if="type_pass === 'password'">
                  <g transform="translate(-539 -430)">
                    <g transform="translate(-5.904 -13.2)">
                      <path d="M11.912,16.37a7.784,7.784,0,0,1-1.962.241,8.268,8.268,0,0,1-6.383-3.075,3.723,3.723,0,0,1,0-4.655c.125-.156.265-.3.4-.452" transform="translate(543 439.992)" fill="none" stroke="#bccbe3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M17.185,12.79a8.822,8.822,0,0,1-2.18,1.884L6.59,6.251A7.969,7.969,0,0,1,10.8,5.06a8.268,8.268,0,0,1,6.383,3.075A3.723,3.723,0,0,1,17.185,12.79Z" transform="translate(542.149 440.738)" fill="none" stroke="#bccbe3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M13.915,11.318a2.421,2.421,0,0,1-.7,1.7L9.82,9.621a2.4,2.4,0,0,1,4.095,1.7Z" transform="translate(541.434 439.883)" fill="none" stroke="#bccbe3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M2.75,2.75,5.739,5.739,8.254,8.254l3.394,3.394,2.514,2.514,2.989,2.989" transform="translate(543 441.25)" fill="none" stroke="#bccbe3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                  </g>
                </svg>
                  <svg v-else width="16" height="16" viewBox="0 0 16 16">
                  <g transform="translate(-539 -430)">
                    <g transform="translate(-0.176 0.493)" opacity="1">
                      <path d="M16.354,8.139a3.728,3.728,0,0,1,0,4.662,8.305,8.305,0,0,1-6.393,3.079A8.293,8.293,0,0,1,3.569,12.8a3.728,3.728,0,0,1,0-4.662A8.305,8.305,0,0,1,9.961,5.06,8.293,8.293,0,0,1,16.354,8.139Z" transform="translate(537.25 427.061)" fill="none" stroke="#92A3BE" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M16.354,8.139a3.728,3.728,0,0,1,0,4.662,8.305,8.305,0,0,1-6.393,3.079A8.293,8.293,0,0,1,3.569,12.8a3.728,3.728,0,0,1,0-4.662A8.305,8.305,0,0,1,9.961,5.06,8.293,8.293,0,0,1,16.354,8.139Z" transform="translate(537.25 427.061)" fill="none" stroke="#92A3BE" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M11.321,13.722a2.4,2.4,0,1,0-2.4-2.4A2.4,2.4,0,0,0,11.321,13.722Z" transform="translate(535.89 426.21)" fill="none" stroke="#92A3BE" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                  </g>
                </svg>
                </span>
              </div>
            </div>
            <div class="flex">
              <div class="flex-col"></div>
              <div class="flex-col">
                <div class="checkbox-cover">
                  <input v-model="remember" type="checkbox" id="auth-cover-remember">
                  <label for="auth-cover-remember">Запомнить меня</label>
                </div>
              </div>
            </div>
          </div>
          <div class="auth-cover__footer">
            <div class="btn btn_line-text" @click="recover()">Забыли пароль?</div>
            <div class="btn btn_primary" @click="onSubmit()">Войти</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Loader from '@/components/LoaderComponent';
import LogoSvg from '@/components/LogoSvg';

export default {
  name: "AuthorizationPage",
  data() {
    return {
      loading: true,
      type_pass: "password",
      password: "",
      login: "",
      remember: false,
    }
  },
  components: {
    Loader,
    LogoSvg
  },
  computed: {
    ...mapGetters("User", ["USER"]),
    ...mapGetters("User", ["AUTH"]),
    ...mapGetters("User", ["ERROR"]),
  },
  methods: {
    ...mapActions("User", ["authorization"]),
    ...mapActions("User", ["errorText"]),
    recover() {
      this.$router.push('/recover')
    },
    onSubmit() {
      if(this.password.length && this.login.length) {
        this.authorization({
          password: this.password,
          login: this.login,
          remember: this.remember ? 'Y' : 'N'
        }).finally(() => {
        });
      } else {
        this.errorText('Не заполнены поля')
      }
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>
