<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="1600.000000pt" height="1600.000000pt" viewBox="0 0 1600.000000 1600.000000"
       preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)"
       fill="currentColor" stroke="none">
      <path d="M1848 10988 l-3 -73 -116 4 -116 3 -6 -257 c-25 -948 33 -5602 69
-5634 23 -21 2503 -66 4139 -76 479 -3 1071 -8 1318 -11 l447 -5 0 120 0 121
75 0 75 0 0 505 0 505 -35 0 -35 0 0 -470 0 -470 -40 0 -40 0 0 470 0 470 -85
0 -85 0 0 -470 c0 -368 -3 -471 -12 -474 -7 -3 -886 -7 -1953 -10 -1067 -3
-2271 -9 -2675 -13 -680 -7 -711 -8 -410 -14 179 -3 946 -9 1705 -13 759 -4
1398 -9 1420 -11 22 -2 464 -4 983 -4 l942 -1 0 -29 0 -29 -182 -6 c-101 -3
-615 -8 -1143 -11 -1418 -8 -2961 -31 -4143 -61 l-264 -7 6 114 c7 115 18 607
36 1489 16 794 32 3262 25 3718 l-7 422 54 0 55 0 8 -2357 c4 -1297 11 -2549
14 -2783 l6 -425 8 535 c4 294 10 1546 14 2782 l6 2247 71 5 c95 7 1380 26
2366 36 664 6 3098 -5 3108 -15 2 -1 -1 -236 -7 -521 -5 -285 -9 -520 -7 -521
1 -1 26 -3 56 -5 l55 -3 -4 565 c-2 311 -5 567 -7 569 -2 2 -464 -2 -1026 -9
-1092 -14 -2008 -10 -3553 15 -489 8 -927 15 -972 15 l-83 0 0 49 0 49 1223 6
c672 4 1971 6 2887 4 l1665 -3 0 -629 c0 -411 3 -630 10 -632 6 -2 15 0 20 4
7 5 9 223 7 647 l-4 640 -2406 1 c-1324 0 -2642 4 -2929 8 l-522 8 -3 -74z"/>
      <path d="M3633 9230 c-12 -5 -26 -18 -32 -29 -8 -13 -11 -195 -11 -554 l0
-534 24 -22 c23 -22 28 -22 247 -19 218 3 225 4 280 29 69 33 129 91 166 161
26 49 28 62 28 163 0 102 -2 114 -30 169 -17 34 -47 73 -73 94 -49 40 -49 45
-13 142 69 187 -39 367 -239 399 -78 13 -316 13 -347 1z m404 -150 c39 -23 59
-59 68 -122 9 -62 -21 -128 -73 -160 -34 -21 -49 -23 -172 -23 l-135 0 -3 150
c-1 82 0 155 2 162 4 10 40 13 143 13 119 0 142 -3 170 -20z m26 -451 c156
-43 201 -297 68 -386 -48 -33 -122 -44 -272 -41 l-134 3 -3 205 c-1 112 0 210
2 217 7 16 282 18 339 2z"/>
      <path d="M6173 9226 l-33 -15 0 -555 0 -555 22 -15 c32 -22 357 -23 443 -1
182 46 302 165 359 355 25 82 31 257 13 361 -26 149 -106 284 -206 348 -28 18
-83 44 -123 58 -66 23 -92 26 -258 30 -149 3 -191 1 -217 -11z m458 -157 c152
-72 223 -205 223 -419 -1 -168 -41 -271 -142 -362 -68 -61 -136 -81 -299 -86
l-143 -4 0 452 0 452 153 -4 c139 -3 157 -5 208 -29z"/>
      <path d="M7483 9230 c-12 -5 -26 -18 -32 -29 -8 -13 -11 -195 -11 -555 0 -523
0 -536 20 -556 19 -19 33 -20 330 -20 297 0 311 1 330 20 25 25 26 61 3 89
-17 20 -27 21 -283 24 l-265 2 0 190 0 190 209 3 c234 3 246 6 246 68 0 60
-17 64 -252 64 l-208 0 0 190 0 190 260 0 261 0 24 25 c31 30 32 66 4 91 -20
18 -42 19 -318 21 -163 1 -306 -2 -318 -7z"/>
      <path d="M8634 9227 c-45 -23 -44 -15 -44 -573 0 -522 0 -532 21 -558 25 -31
57 -33 93 -5 l26 20 0 415 c0 264 4 413 10 409 5 -3 139 -191 297 -418 158
-226 296 -420 306 -429 24 -25 59 -22 85 5 l22 23 0 535 c0 595 2 579 -64 579
-67 0 -66 10 -66 -448 0 -226 -3 -413 -7 -415 -5 -3 -129 169 -278 381 -360
515 -352 505 -401 479z"/>
      <path d="M10954 9230 c-67 -14 -110 -36 -158 -80 -65 -60 -91 -118 -90 -205 1
-146 79 -238 257 -302 112 -40 200 -103 233 -165 31 -60 5 -149 -61 -207 -53
-46 -94 -61 -167 -61 -79 0 -138 28 -175 82 -58 84 -88 97 -131 58 -31 -29
-27 -67 13 -126 128 -185 412 -202 569 -34 60 64 88 127 94 212 4 55 1 74 -19
114 -45 95 -147 177 -299 243 -153 66 -185 103 -178 202 3 39 11 66 24 80 54
62 185 78 263 34 23 -14 54 -25 67 -25 30 0 64 33 64 63 0 75 -179 144 -306
117z"/>
      <path d="M11850 9234 c-8 -4 -23 -13 -32 -22 -17 -14 -18 -53 -18 -559 0 -530
0 -543 20 -563 26 -26 60 -25 88 0 21 20 22 28 22 204 l0 184 153 4 c179 5
236 22 316 94 59 53 96 123 116 219 12 61 12 79 0 140 -29 135 -117 235 -245
276 -53 18 -96 22 -235 25 -93 2 -177 1 -185 -2z m405 -158 c89 -42 124 -91
132 -187 6 -64 -14 -142 -49 -189 -11 -16 -43 -40 -70 -55 -43 -24 -64 -28
-167 -32 -65 -3 -130 -3 -145 1 l-26 6 0 240 0 240 138 0 c123 0 142 -3 187
-24z"/>
      <path d="M5167 9212 c-15 -17 -121 -273 -337 -817 -100 -253 -107 -287 -58
-314 53 -30 77 0 150 184 l24 60 265 3 266 2 42 -107 c46 -117 71 -153 108
-153 29 0 63 33 63 63 0 34 -417 1068 -437 1083 -25 19 -67 17 -86 -4z m152
-488 c54 -137 96 -252 94 -256 -2 -5 -94 -8 -204 -8 -147 0 -199 3 -199 12 0
11 185 482 197 501 3 5 7 7 9 4 2 -2 48 -116 103 -253z"/>
      <path d="M13217 9213 c-12 -15 -136 -318 -403 -990 -32 -79 -29 -122 9 -143
24 -13 30 -13 55 0 21 12 37 41 73 130 l46 115 265 3 266 2 42 -107 c43 -109
75 -153 111 -153 27 0 59 35 59 65 0 35 -416 1067 -437 1082 -24 18 -67 16
-86 -4z m159 -505 c52 -132 94 -241 94 -244 0 -2 -92 -4 -205 -4 -129 0 -205
4 -205 10 0 5 41 112 91 237 50 126 95 239 101 253 9 23 10 24 20 6 6 -10 53
-126 104 -258z"/>
      <path d="M4026 7707 c-26 -26 -436 -1044 -436 -1082 0 -28 35 -65 62 -65 33 0
63 42 107 153 l43 107 265 -2 265 -3 46 -113 c36 -87 54 -118 75 -130 25 -15
30 -15 55 -2 53 30 49 45 -173 600 -205 514 -227 560 -271 560 -8 0 -25 -10
-38 -23z m89 -343 c19 -49 61 -159 94 -243 34 -85 61 -159 61 -163 0 -4 -92
-8 -205 -8 -113 0 -205 3 -205 6 0 3 46 120 102 260 60 152 105 251 110 246 5
-5 24 -49 43 -98z"/>
      <path d="M5015 7705 l-25 -24 0 -539 0 -539 23 -21 c29 -27 61 -28 87 -2 19
19 20 33 20 205 l0 185 108 0 c145 0 235 15 294 47 139 76 217 251 184 412
-34 161 -145 263 -316 290 -36 6 -129 11 -208 11 -138 0 -144 -1 -167 -25z
m460 -150 c149 -92 137 -350 -20 -424 -39 -18 -66 -22 -163 -23 -65 0 -129 0
-144 1 l-28 1 0 241 0 241 153 -4 c151 -3 155 -4 202 -33z"/>
      <path d="M6416 7708 c-27 -26 -436 -1039 -436 -1083 0 -30 32 -65 59 -65 36 0
62 36 106 145 l44 110 266 0 267 0 46 -113 c36 -87 54 -118 75 -130 25 -15 30
-15 55 -2 52 30 48 43 -173 598 -204 512 -228 562 -270 562 -8 0 -25 -10 -39
-22z m109 -398 c119 -304 135 -345 135 -352 0 -5 -92 -8 -205 -8 -113 0 -205
3 -205 7 0 23 204 513 211 506 4 -4 33 -73 64 -153z"/>
      <path d="M7405 7705 l-25 -24 0 -534 c0 -525 0 -535 21 -561 25 -31 61 -34 89
-6 19 19 20 33 20 205 l0 185 118 0 117 0 121 -192 c131 -211 154 -234 202
-208 20 12 28 24 30 52 3 32 -10 59 -97 196 -56 88 -103 166 -106 174 -4 9 3
19 17 25 50 23 125 102 151 160 72 161 48 323 -65 436 -90 90 -186 117 -426
117 -138 0 -144 -1 -167 -25z m460 -150 c161 -99 130 -381 -47 -435 -44 -13
-250 -23 -287 -13 -20 5 -21 12 -21 245 l0 240 153 -4 c151 -3 155 -4 202 -33z"/>
      <path d="M8506 7709 c-17 -13 -26 -30 -26 -49 0 -19 9 -36 26 -49 24 -19 40
-21 155 -21 l129 0 0 -493 0 -494 23 -21 c30 -28 58 -28 85 1 22 23 22 24 22
515 l0 492 129 0 c115 0 131 2 155 21 17 13 26 30 26 47 -1 69 -14 72 -378 72
-308 0 -320 -1 -346 -21z"/>
      <path d="M9705 7705 l-25 -24 0 -534 c0 -525 0 -535 21 -561 24 -31 57 -34 90
-7 l24 19 2 418 c2 229 5 419 7 422 3 2 80 -96 171 -219 92 -123 176 -229 187
-235 46 -25 63 -9 246 234 l177 236 3 -424 c2 -419 2 -424 24 -447 27 -28 57
-29 86 -3 l22 21 -2 546 -3 546 -27 20 c-23 17 -32 19 -52 9 -14 -6 -117 -133
-232 -286 -113 -152 -210 -276 -213 -276 -4 0 -23 21 -43 48 -19 26 -108 145
-198 265 -202 270 -216 282 -265 232z"/>
      <path d="M11210 7710 l-25 -19 0 -546 0 -547 24 -19 c22 -18 43 -19 323 -19
192 0 306 4 319 11 41 21 44 86 5 111 -6 4 -129 8 -273 8 l-263 0 0 195 0 195
210 0 c197 0 211 1 230 20 11 11 20 31 20 45 0 14 -9 34 -20 45 -19 19 -33 20
-230 20 l-210 0 0 190 0 190 260 0 c247 0 261 1 280 20 31 31 30 76 -3 102
-20 16 -53 18 -322 18 -281 0 -302 -2 -325 -20z"/>
      <path d="M12366 7709 l-26 -20 0 -544 0 -544 22 -21 c12 -11 28 -20 36 -20 17
0 55 20 64 34 4 6 8 197 8 424 0 306 3 413 11 410 7 -2 41 -46 77 -98 225
-328 516 -738 534 -752 27 -23 53 -23 78 2 20 20 20 33 20 565 l0 544 -22 21
c-27 25 -49 25 -79 1 l-24 -19 -5 -423 -5 -423 -299 428 c-164 235 -308 433
-320 441 -27 20 -38 19 -70 -6z"/>
      <path d="M13683 7720 c-46 -19 -55 -69 -18 -105 23 -24 29 -25 155 -25 l130 0
0 -490 0 -491 25 -24 c29 -29 55 -32 84 -7 21 17 21 22 22 482 0 256 3 479 6
497 l5 31 134 4 c149 3 167 11 167 68 0 65 1 65 -361 67 -180 1 -337 -2 -349
-7z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoSvg",
}
</script>
