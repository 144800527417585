<template>
  <div class="web-container">
    <template v-if="loader"><Loader /></template>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Loader from "@/components/LoaderComponent";

export default {
  name: "HomePage",
  data() {
    return {
      loader: true,
    }
  },
  components: {
    Loader
  },
  computed: {
    ...mapGetters("User", ["USER"]),
  },
  methods: {
    ...mapActions("User", ["fetchUser"]),
  },
  mounted() {
    this.fetchUser({auth: false}).finally(() => {
      this.loader = false;
    })
  }
}
</script>
